import { Injectable, Signal, inject, signal } from '@angular/core';
import { ASD, AssignCouponResponse, CreateASDRequest, CreateASDResponse, CreateASDResponseSuccess, GetAsdListRequest, GetAsdListResponse, GetAsdResponse, GetInfoResponse } from '../model/asd.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { Router } from '@angular/router';
import { response } from 'express';
@Injectable({
  providedIn: 'root',
})
export class AsdService {
  http = inject(HttpClient);
  router = inject(Router);  
  asdPoints = signal<string>('');
  totalAsd  = signal<number>(0);
  loggedASD = signal<Partial<ASD>>({});

  //Metodo semplificato per il momento, dopo il 27 maggio lo aggiustiamo o se avete tempo di migliorarlo prima va meglio!
  addAsd(createASDRequest: CreateASDRequest): Observable<CreateASDResponse> {
    return this.http
      .post<CreateASDResponse>(`${env.apiUrl}/asd/register`, createASDRequest);
  }

  setASD(asd: Partial<ASD>):void {
    this.loggedASD.set(asd);
  }

  changePoints(points: any) {
    this.asdPoints.set(points)
  }

  setTotalAsd(total: number) {
    this.totalAsd.set(total);
  }

  getAsdList(searchAsdList?: GetAsdListRequest): Observable<GetAsdListResponse> {
    let params = new HttpParams();
    if(searchAsdList?.clubName !== undefined) params = params.append('clubName', searchAsdList.clubName);
    if(searchAsdList?.mail !== undefined) params = params.append('mail', searchAsdList.mail);
    if(searchAsdList?.taxCode !== undefined) params = params.append('taxCode', searchAsdList.taxCode);
    if(searchAsdList?.municipality !== undefined) params = params.append('municipality', searchAsdList.municipality);
    if(searchAsdList?.municipalityName !== undefined) params = params.append('municipalityName', searchAsdList.municipalityName);
    if(searchAsdList?.province !== undefined) params = params.append('province', searchAsdList.province);
    if(searchAsdList?.region !== undefined) params = params.append('region', searchAsdList.region);

    return this.http.get<GetAsdListResponse>(`${env.apiUrl}/asd/`, { params })
    .pipe(
      map((response: GetAsdListResponse) => {
       return response;
      })
    )
  }

  getAsd(representativeEditionId: string): Observable<GetAsdResponse> {
    return this.http.get<GetAsdResponse>(`${env.apiUrl}/asd/${representativeEditionId}`)
  }

  updateASD(asdId: string, updatedAsd: Partial<ASD>): Observable<GetAsdResponse> {
    return this.http.put<GetAsdResponse>(`${env.apiUrl}/asd/${asdId}`, {...updatedAsd})
  }

  deleteASD(asdId: string) {
    return this.http.delete(`${env.apiUrl}/asd/delete/${asdId}`);
  }

  assignCoupon(coupon: string): Observable<AssignCouponResponse> {
    return this.http.post<AssignCouponResponse>(`${env.apiUrl}/asd/assignCoupon/`, {coupon: coupon})
  }
  
  getInfo(): Observable<GetInfoResponse> {
    return this.http.get<GetInfoResponse>(`${env.apiUrl}/info`);

  }
}
